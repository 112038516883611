<template>
<div class="login">
  <div class="top">
    <img :src="logoUrl" class="logo" v-if="logoUrl!=='' " alt=""/>
    <div v-else></div>
    <div @click="showPicker=true">
      {{selectedValues}}
      <img src="../assets/images/zk255.png" alt=""/>
    </div>
    <van-popup v-model:show="showPicker" round position="bottom">
      <van-picker
          v-model="valueLange"
          :columns="columns"
          @cancel="showPicker = false"
          @confirm="onConfirm"
          :confirm-button-text="$t('home.qd')"
          :cancel-button-text="$t('home.qx')"
      />
    </van-popup>
  </div>

<!--邀请码-->
  <van-popup v-model:show="showInvitationCode"  closeable :close-on-click-overlay="false" @close="invitationCode=''" v-if="showInvitationCode">
    <div class="password">
      <div class="title">{{$t('login.yqm')}}</div>
      <van-cell-group inset>
        <van-field v-model="invitationCode"  center :placeholder="$t('login.yqmP')" maxlength="6"/>
      </van-cell-group>
      <div class="confirm-btn">
        <van-button class="confirm" @click="logHttp(true)">{{$t('home.qd')}}</van-button>
      </div>
    </div>
  </van-popup>
  <div class="bottom">
    <van-button class="logLnNow" @click="logLnNow">{{$t('login.ljdl')}}</van-button>
  </div>
  <Password :showPassword="showPassword" @changePassword="changePassword" v-if="showPassword"></Password>

</div>
</template>
<script setup>
import Password from "@/views/components/password-popup.vue";
import {getCurrentInstance, onBeforeMount, ref} from 'vue'
import {Toast} from "vant";
import {getProjectIcon, userLogin} from "../api";
import { useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
const {locale } = useI18n()
const router = useRouter()
const { $t } = getCurrentInstance().proxy;
const showPicker=ref(false)
const selectedValues = ref('中文繁體');
const valueLange = ref();
const columns =ref([
  { text: '中文繁體', value: 'zh-HK' },
  { text: 'English', value: 'en-US' },
  // { text: '韩文', value: 'ko-KR' },
  // { text: '日文', value: 'ja-JP' },
  // { text: '中文简体', value: 'zh-CN' },

])
const onConfirm=( selectedOptions )=>{
  locale.value=selectedOptions.value
  sessionStorage.setItem('lange',selectedOptions.value)
  selectedValues.value=selectedOptions.text
  showPicker.value=false
  valueLange.value=selectedOptions.value

}
const logoUrl=ref('')
const getLogo=()=>{
  getProjectIcon().then(res=>{
    logoUrl.value=res.data
  })
}
onBeforeMount(()=>{
  getLogo()
  selectedValues.value=locale.value==='zh-HK'?'中文繁體':'English'
  valueLange.value=[locale.value]
  sessionStorage.removeItem('token')
  sessionStorage.removeItem('address')
  sessionStorage.removeItem('userId')
  sessionStorage.setItem('activeTab',0)
  if( (window.statter && window.statter.isStatterEnv())===undefined){
    Toast($t('login.statterEnv'))
    return
  }
  let current=window.statter.getCurrentWallet()
  currentWallet.value= current.substring(0,3)
})
//登录

const currentWallet=ref('')
const walletAddress=ref('')
// const walletAddress=ref('stt18b4bc486ca0ff0e90c47e208c7f7118')
// const walletAddress=ref('stt1eb7be386bd0790368a8afe4995b241a')
// const walletAddress=ref('stt94ed358a8e782e2d3843693cb22409cc')
const logLnNow=()=>{
  Toast.loading({
    duration:0,
    forbidClick:true,
    icon: require('../assets/images/loading.gif'),
    iconSize:200,
    'class-name':'toast-loading'
  })
  if(currentWallet.value==='STT' ){
    let current=window.statter.getCurrentWallet()
    walletAddress.value= current.substring(4,current.length)
    getInvitationCode()
    logHttp(false)
  }else{
    Toast.clear();

    Toast($t('login.chainNot'))
    // getInvitationCode()
    // logHttp(false)
  }
}
const logHttp=(setInvitationCode)=>{
    userLogin({
      "address":walletAddress.value,   // 用户地址
      "invitationCode":setInvitationCode?invitationCode.value:''  // 邀请码
    })
    .then(res=>{
      Toast.clear();
      if(res.code==='200'){
        sessionStorage.setItem('address',walletAddress.value)
        sessionStorage.setItem('token', res.data.token)
        sessionStorage.setItem('userId', res.data.userId)
        showPassword.value=true
      }else if(res.code==='521'){
        showInvitationCode.value=true
        // invitationCode.value=''
      }else if(res.code==='522'){
        showInvitationCode.value=true
        // invitationCode.value=''
        Toast(res.msg)
      }else{
        Toast(res.msg)

      }
    })
  .catch(()=>{
    Toast.clear();

  })


}
// 密码
const showPassword=ref(false)
const changePassword=(val)=>{
  // 1失败 2成功
  if(val===1){
    showInvitationCode.value=false
    showPassword.value=false

  }else {
    showPassword.value=false
    showInvitationCode.value=false

    // sessionStorage.setItem('activeTab','0')
    // router.push('/home')
    router.push('/balance')

  }

  // Toast('成功')
}
// 邀请码
const getInvitationCode=()=>{
  let str = window.location.href
  let index = str.indexOf('?inviteCode=')
  let strnew = str.slice(index + 12, index + 18)
  if (index != -1) {
    invitationCode.value = strnew
  } else {
    invitationCode.value = ''
  }
}

const invitationCode=ref('')
const showInvitationCode=ref(false)

</script>
<style scoped lang="less">
.login{
  height: 100vh;
  background-image: url("~@/assets/images/bg.png");
  background-size: 100% 100%;
  padding: 22px 20px;
  box-sizing: border-box;

  .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo{
      width: 96px;
      height: 37px;
    }
    div{
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;
      img{
        width: 10px;
        height: 10px;
        margin-left: 5px;
      }
    }

    /deep/.van-picker{
      background: #232A2E;
    }
    /deep/.van-picker-column__item{
      color: #FFFFFF;
    }
    /deep/.van-picker__confirm{
      color: #6FFEE8;
    }

    /deep/.van-picker__mask{
      background: none;
    }
  }

  /deep/.van-popup {
    background: #232A2E;
    border-radius: 25px;
  }
  /deep/.van-popup__close-icon--top-right{
    top:30px;
    height: 24px;
  }
  .password{
    box-sizing: border-box;
    width: 312px;
    //height: 320px;
    background: #232A2E;
    border-radius: 25px;
    padding: 28px 19px 31px 19px;
    .title{
      font-weight: bold;
      font-size: 18px;
      color: #FFFFFF;
      margin-bottom: 30px;
    }
    .confirm-btn{
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      .confirm{
        width: 187px;
        height: 56px;
        background: linear-gradient(90deg, #57F6CD, #AF65EE);
        box-shadow: 0px 5px 14px 0px rgba(181,186,255,0.3);
        border-radius: 28px;
        font-weight: bold;
        font-size: 17px;
        color: #000000;
        text-align: center;
        line-height: 56px;
        border: none;
      }
    }
    /deep/ .van-cell-group{
      width: 100%;
      height: 62px;
      background: #000000;
      border-radius: 31px;
    }
    /deep/ .van-cell-group--inset{
      margin: 0;
      .van-cell{
        font-weight: 500;
        font-size: 17px;
        color: #7E8D95;
        width: 264px;
        height: 62px;
        background: #000000;
        border-radius: 31px;
      }
      .van-field__control{
        text-align: center;
        font-weight: bold;
        font-size: 21px;
        color: #FFFFFF;
      }
    }
  }
  /deep/.van-field__control::placeholder{
    font-weight: 500;
    font-size: 17px;
    color: #7E8D95;
  }
  .bottom{
    position: absolute;
    bottom: 83px;
    width: 89%;
    .logLnNow{
      width: 187px;
      height: 56px;
      background: linear-gradient(90deg, #57F6CD, #AF65EE);
      box-shadow: 0px 5px 14px 0px rgba(181,186,255,0.3);
      border-radius: 28px;
      font-weight: bold;
      font-size: 19px;
      color: #000000;
      border: none;
      margin: 0 auto;
    }
  }

}


</style>
